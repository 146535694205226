import * as Types from '../../lib/api.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MusicReleaseQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Types.PublicationStatus>;
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']['input']>;
}>;


export type MusicReleaseQuery = { __typename?: 'Query', musicReleases: Array<{ __typename?: 'MusicRelease', content?: string | null, dateReleased?: any | null, documentId: string, richContent?: any | null, title?: string | null, link?: Array<{ __typename?: 'ComponentSharedLinks', name?: string | null, url?: string | null } | null> | null } | null> };


export const MusicReleaseDocument = gql`
    query MusicRelease($status: PublicationStatus, $locale: I18NLocaleCode) {
  musicReleases(status: $status, locale: $locale) {
    content
    dateReleased
    documentId
    link {
      name
      url
    }
    richContent
    title
  }
}
    `;

/**
 * __useMusicReleaseQuery__
 *
 * To run a query within a React component, call `useMusicReleaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useMusicReleaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMusicReleaseQuery({
 *   variables: {
 *      status: // value for 'status'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMusicReleaseQuery(baseOptions?: Apollo.QueryHookOptions<MusicReleaseQuery, MusicReleaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MusicReleaseQuery, MusicReleaseQueryVariables>(MusicReleaseDocument, options);
      }
export function useMusicReleaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MusicReleaseQuery, MusicReleaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MusicReleaseQuery, MusicReleaseQueryVariables>(MusicReleaseDocument, options);
        }
export function useMusicReleaseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MusicReleaseQuery, MusicReleaseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MusicReleaseQuery, MusicReleaseQueryVariables>(MusicReleaseDocument, options);
        }
export type MusicReleaseQueryHookResult = ReturnType<typeof useMusicReleaseQuery>;
export type MusicReleaseLazyQueryHookResult = ReturnType<typeof useMusicReleaseLazyQuery>;
export type MusicReleaseSuspenseQueryHookResult = ReturnType<typeof useMusicReleaseSuspenseQuery>;
export type MusicReleaseQueryResult = Apollo.QueryResult<MusicReleaseQuery, MusicReleaseQueryVariables>;