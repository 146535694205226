import React, { useEffect } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import './BaseLayout.css';
import { AppContext } from '../context/AppContext';
import ReactGA from "react-ga4";
import HeaderMusic from './HeaderMusic';

const BaseLayout : React.FC = () => {
  const appContext = React.useContext(AppContext);
    const location = useLocation();

    useEffect(() => {
      if(process.env.REACT_APP_ENV === 'production' && process.env.REACT_APP_GA4_ID) {
        ReactGA.send({ hitType: "pageview", page: location.pathname, title: appContext?.pageTitle });
        // console.log("page", location.pathname, "title", appContext?.pageTitle);
      }
    }, [location, appContext]);

    useEffect(() => {
      if(appContext) {
        if(appContext.pageTitle === null) {
          document.title = appContext?.appTitle ?? '';
        }
        else {
          document.title = appContext.pageTitle + ' | ' + appContext.appTitle;
        }
      }
    }, [appContext]);
    
      return (
        <>

          {/* {location.pathname.startsWith("/music") ? <HeaderMusic /> : <Header />} */}
          <Header />
          <ScrollRestoration 
            // getKey={(location) => location.pathname}
          />
          <div className="main-content">
            <div className="width-restricted-area">
              <Outlet />
            </div>
          </div>
          <Footer />
        </>
      );
  }
  
  export default BaseLayout;