import React, { useEffect } from 'react';
import { useMusicQuery } from './music.query.graphql.generated';
import { useMusicReleaseQuery } from '../../components/MusicReleases/musicreleases.query.graphql.generated';
import { PublicationStatus } from '../../lib/api.types';
import { useTranslation } from 'react-i18next';
// import LatestArticles  from '../../components/LatestArticles';
import RichContentItem from '../../components/RichContentItem';
import LoadingSpinnerWorm from '../../components/LoadingSpinnerWorm';
import { AppContext } from '../../context/AppContext';
import MusicCard from './musicCard';

import './music.css';


const Music: React.FC = () => {
    const appContext = React.useContext(AppContext);
    const { t, i18n: { language: currLang } } = useTranslation();
    const { data, loading, error } = useMusicQuery({
        variables: {
            status: (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development') ? PublicationStatus.Draft : PublicationStatus.Published,
            locale: currLang,
            }
        }
    );

    const { data: releasesData, loading: releasesLoading, error: releasesError } = useMusicReleaseQuery({
        variables: {
            status: (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development') ? PublicationStatus.Draft : PublicationStatus.Published,
            locale: currLang
            }
        }
    );

    useEffect(() => {
        if (appContext) {
            // appContext.setPageTitle(data?.home?.title || '');
            appContext.setPageTitle(t('music.title'));
        }
    }, [t, appContext]);

    return (
        <>

            {loading && <div className="fade-in"><LoadingSpinnerWorm /></div>}
            {error && <p>{t('general.error')}: {error.message}</p>}
            {data && data.music &&  
                data && data.music && <div className="fade-in"><RichContentItem content={{ ...data.music, title: data.music.title || '', richContent: data.music.richContent || [] }} /></div>
            }

            {releasesLoading && <div className="fade-in"><LoadingSpinnerWorm /></div>}
            {releasesError && <p>{t('general.error')}: {releasesError.message}</p>}

            {releasesData && releasesData.musicReleases &&  
                releasesData && releasesData.musicReleases && releasesData.musicReleases.map((release, index) => (
                    // release &&
                    //     <div className="fade-in" key={index}>
                    //         release && <MusicCard release={{ ...release, title: release.title || '' }} />
                    //     </div>
                    <p>article: {release?.title ?? 'No title'}</p>
                ))
            }
                
            <div className="music-preview-grid">
                <div className="music-preview">
                    <h3>Melodies in Ebony and Ivory</h3>
                    <p className="meta">{t('music.released')}: 9/2024. {t('music.listen')}: <a href="/music">Spotify</a> | <a href="/music">Apple Music</a> | <a href="/music">SoundCloud</a></p>
                    <iframe 
                        title="soundcloud"
                        width="100%" 
                        height="300" 
                        allow="autoplay" 
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1929838430&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                    </iframe>
                </div>
                <div className="music-preview">
                    <h3>Echoes from Eternity</h3>
                    <p className="meta">{t('music.released')}: 10/2024. {t('music.listen')}: <a href="/music">SoundCloud</a></p>
                    <iframe 
                        title="soundcloud"
                        width="100%" 
                        height="300" 
                        allow="autoplay" 
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1933307339&color=%0000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                    </iframe>
                </div>
                <div className="music-preview">
                    <h3>Master of the Moon</h3>
                    <p className="meta">{t('music.released')}: 1/2024. {t('music.listen')}: <a href="/music">SoundCloud</a></p>
                    <iframe 
                        title="soundcloud"
                        width="100%" 
                        height="300" 
                        allow="autoplay" 
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1712893989&color=%0000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                    </iframe>
                </div>
                <div className="music-preview">
                    <h3>Lapsuuden Sankarille (version 2, cover w Pnuk)</h3>
                    <p className="meta">{t('music.released')}: 3/2023. {t('music.listen')}: <a href="/music">SoundCloud</a></p>
                    <iframe 
                        title="soundcloud"
                        width="100%" 
                        height="300" 
                        allow="autoplay" 
                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1585014739&color=%0000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true">
                    </iframe>
                </div>
            </div>
            

        </>
    );
};

export default Music;