import { saveLanguagePreference } from '../../utils/LanguageStorage';
import { useTranslation } from 'react-i18next';
import './style.css';

const OnTextLanguageSwitcher: React.FC = () => {
    const { i18n: { changeLanguage, language: currLang } } = useTranslation();

    const handleLanguageChange = (lang: string) => {
        changeLanguage(lang);
        saveLanguagePreference(lang);
    }

    return (
        <div>
           {currLang === 'en' && <button className="change-langugage-button" onClick={() => handleLanguageChange('fi')}>Vaihda kieleksi Suomi</button>}
           {currLang === 'fi' && <button className="change-langugage-button" onClick={() => handleLanguageChange('en')}>Change language to english</button>}
        </div>
    );
};

export default OnTextLanguageSwitcher;