import React, { useEffect } from 'react';
import { useHomeQuery } from './home.query.graphql.generated';
import { PublicationStatus } from '../../lib/api.types';
import { useTranslation } from 'react-i18next';
import LatestArticles  from '../../components/LatestArticles';
import RichContentItem from '../../components/RichContentItem';
import LoadingSpinnerWorm from '../../components/LoadingSpinnerWorm';
import { AppContext } from '../../context/AppContext';
import OnTextLanguageSwitcher from '../../components/OnTextLanguageSwitcher';
import './home.css';


const Home: React.FC = () => {
    const appContext = React.useContext(AppContext);
    const { t, i18n: { language: currLang } } = useTranslation();
    const { data, loading, error } = useHomeQuery({
        variables: {
            status: (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development') ? PublicationStatus.Draft : PublicationStatus.Published,
            locale: currLang,
            }
        }
    );

    useEffect(() => {
        if (appContext && data) {
            appContext.setPageTitle(data?.home?.title || '');
        }
    }, [data, appContext]);

    return (
        <>
            {loading && <div className="fade-in"><LoadingSpinnerWorm /></div>}
            {error && <div className="fade-in"><p>{t('general.error')}: {error.message}</p></div>}
            
                {data && data.home &&  
                    data && data.home && <div className="fade-in"><RichContentItem content={{ ...data.home, title: data.home.title || '', richContent: data.home.richContent || [] }} /></div>
                }
                <div className="fade-in"><OnTextLanguageSwitcher /></div>
                <LatestArticles />

        </>
    );
};

export default Home;