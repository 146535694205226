import Home from '../pages/Home';
// import Articles from '../pages/Articles';
import Contact from '../pages/Contact';
// import Article from '../pages/Article';
import Music from '../pages/Music';

export const baseRoutes = [
        { path: '/', name: 'home', element: <Home />},
        // { path: '/articles', name: 'articles', element: <Articles />}
        //         , children: 
        // [
        //    { path: '/articles/:articleyear/:articlemonth/:slug', name: 'article', element: <Article /> }
        { path: '/music', name: 'music', element: <Music />},
        { path: '/contact', name: 'contact', element: <Contact />}
];

// check this out https://reactrouter.com/en/main/route/route
// THERE IS THE LANGUAGE SWITCHER URL