import * as Types from '../../lib/api.types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MusicQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Types.PublicationStatus>;
  locale?: Types.InputMaybe<Types.Scalars['I18NLocaleCode']['input']>;
}>;


export type MusicQuery = { __typename?: 'Query', music?: { __typename?: 'Music', content?: string | null, documentId: string, publishedAt?: any | null, richContent?: any | null, title?: string | null, updatedAt?: any | null } | null };


export const MusicDocument = gql`
    query Music($status: PublicationStatus, $locale: I18NLocaleCode) {
  music(status: $status, locale: $locale) {
    content
    documentId
    publishedAt
    richContent
    title
    updatedAt
  }
}
    `;

/**
 * __useMusicQuery__
 *
 * To run a query within a React component, call `useMusicQuery` and pass it any options that fit your needs.
 * When your component renders, `useMusicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMusicQuery({
 *   variables: {
 *      status: // value for 'status'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useMusicQuery(baseOptions?: Apollo.QueryHookOptions<MusicQuery, MusicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MusicQuery, MusicQueryVariables>(MusicDocument, options);
      }
export function useMusicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MusicQuery, MusicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MusicQuery, MusicQueryVariables>(MusicDocument, options);
        }
export function useMusicSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MusicQuery, MusicQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MusicQuery, MusicQueryVariables>(MusicDocument, options);
        }
export type MusicQueryHookResult = ReturnType<typeof useMusicQuery>;
export type MusicLazyQueryHookResult = ReturnType<typeof useMusicLazyQuery>;
export type MusicSuspenseQueryHookResult = ReturnType<typeof useMusicSuspenseQuery>;
export type MusicQueryResult = Apollo.QueryResult<MusicQuery, MusicQueryVariables>;