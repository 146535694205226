import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../context/AppContext';

const NotFoundPage: React.FC = () => {
    const appContext = React.useContext(AppContext);
    const navigate = useNavigate()

    const { t } = useTranslation();

    useEffect(() => {
            appContext?.setPageTitle(t('404NotFound.title'));    
    }, [t, appContext]);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>{t('404NotFound.title')}</h1>
            <p>{t('404NotFound.message')}.</p>
            <Link to="/">{t('404NotFound.backToHome')}</Link>  ||  <Link 
                to={'..'} 
                onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }}>{t('404NotFound.backToPrevious')}</Link>
        </div>
    );
};

export default NotFoundPage;