import React, { useEffect } from 'react';
import RichContentItem from '../../components/RichContentItem';
import { useContactQuery } from './contact.query.graphql.generated';
import { PublicationStatus } from '../../lib/api.types';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../components/LoadingSpinnerWorm';
import { AppContext } from '../../context/AppContext';

const Contact: React.FC = () => {
    const appContext = React.useContext(AppContext);
    const { t, i18n: { language: currLang } } = useTranslation();
    const { data, loading, error } = useContactQuery({
        variables: {
            status: (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development') ? PublicationStatus.Draft : PublicationStatus.Published,
            locale: currLang,
            }
        }
    );

    useEffect(() => {
        if (appContext && data) {
            appContext.setPageTitle(data?.contact?.title || '');
        }
    }, [data, appContext]);

    return (
        <>
            {loading && <div className="fade-in"><LoadingSpinner /></div>}
            {error && <div className="fade-in"><p>{t('general.error')}: {error.message}</p></div>}
            {data && (
                data && data.contact && 
                <div className="fade-in">
                    <img className="cover" src={(process.env.REACT_APP_MEDIA_URI || '') + data.contact.cover?.url} alt={data.contact.title ?? ''} />
                    <RichContentItem content={{ ...data.contact, title: data.contact.title || '', richContent: data.contact.richContent || [] }} />
                </div>
            )}
        </>
    );
};

export default Contact;