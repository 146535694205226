import { Routes, Route, ScrollRestoration, BrowserRouter, createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import NotFoundPage from './pages/404';
import BaseLayout from './layout/BaseLayout';
import { baseRoutes } from './navigation/AllRoutes';
import { client } from './lib/ApolloClient';
import { ApolloProvider } from '@apollo/client';
import Article from './pages/Article';
import { AppContextProvider } from './context/AppContext';
import { getStoredLanguage } from './utils/LanguageStorage';
import { useEffect } from 'react';
import i18n from './i18n';
import ReactGA from "react-ga4";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      ...baseRoutes.map(route => ({
        path: route.path,
        element: route.element
      })),
      {
        path: "/articles/:articleyear/:articlemonth/:id/:slug",
        element: <Article />
      },
      {
        path: "*",
        element: <NotFoundPage />
      }
    ]
  }
]);

function App() {
  
  process.env.REACT_APP_ENV === 'production' && process.env.REACT_APP_GA4_ID && ReactGA.initialize(process.env.REACT_APP_GA4_ID);

  useEffect(() => {
    const storedLang = getStoredLanguage();
    i18n.changeLanguage(storedLang);
  }, []);

  return (
    <ApolloProvider client={client}>
      <AppContextProvider>
        <RouterProvider router={router} />
      </AppContextProvider>
    </ApolloProvider>
  );
    }

export default App;